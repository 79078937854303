<template>
  <v-flex>
    <v-container style="max-width: 510px;margin-top: 30px">
      <v-layout
        justify-center
        row
        wrap
      >
        <v-flex xs12>
          <div align="center">
            <v-img
              :src="require('@/assets/token_expirado.png')"
              width="210px"
              height="222px"
              style="text-align: center"
            />
          </div>
        </v-flex>
        <v-flex xs12>
          <p class="expired-link">
            Link caducado
          </p>
        </v-flex>
        <v-flex xs12>
          <p class="expired-text">
            El tiempo fue expirado.
            Para validar cuenta ingresa nuevamente tu correo electrónico
          </p>
        </v-flex>
        <v-flex
          xs12
          mt-2
          :style=" emailErrors.length > 0 ? 'margin-bottom: 13px' : ''"
        >
          <v-text-field
            v-model="email"
            type="email"
            name="email"
            autocomplete="email"
            label="Correo electrónico"
            full-width
            class="inputs"
            :error-messages="emailErrors"
          />
        </v-flex>
        <v-flex
          xs12
          mt-3
        >
          <v-btn
            style="border-radius: 5px;height: 50px;"
            block
            color="info2"
            type="submit"
            @click="sendEmail()"
          >
            <p class="button-text">
              Enviar
            </p>
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          mt-5
        >
          <v-layout
            justify-center
          >
            <img
              src="@/assets/Logo-Mandomedio-PDE.png"
              alt="logo-mandomedio"
              width="172px"
            >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="success_modal"
      max-width="462"
      style="margin: 0px"
      persistent
    >
      <v-card class="modal-signup-complete">
        <v-layout
          justify-center
        >
          <img
            style="margin-bottom: 50px;margin-top: 90px"
            src="@/assets/email.svg"
            alt="logo"
          >
        </v-layout>
        <v-layout
          justify-center
        >
          <p class="title-modal-signup">
            Revisa tu correo
          </p>
        </v-layout>
        <v-layout
          justify-center
        >
          <p class="text-modal-signup-complete">
            Te hemos enviado el link de confirmación a
            {{ email }} para que puedas iniciar sesión.
          </p>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-container>
              <RouterLink
                to="/"
                style="text-decoration: none"
              >
                <v-btn
                  style="border-radius: 5px;height: 50px;"
                  block
                  color="info2"
                >
                  <p class="button-text">
                    Continuar
                  </p>
                </v-btn>
              </RouterLink>
            </v-container>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import axios from 'axios';
import { parseJwt } from '@/utils/jwt';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'LinkExpired',
  mixins: [
    JWTConfigMixin,
  ],
  data() {
    return {
      success_modal: false,
      emailErrors: [],
      email: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.token) {
        axios.put(this.$store.state.endpoints.offers.admin.activateAccount,
          { token: this.$route.query.token }, this.jwtConfig)
          .then(resp => {
            this.$store.commit('updateToken', resp.data.token);
            this.$router.replace({ query: null });
            if (!this.$store.state.isAdmin) {
              axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
                .then(res => {
                  this.$store.commit('setLoginDetails', res.data);
                  this.$store.commit('unsetLoading');
                  this.$emit('loginSuccess', '');
                  this.$store.commit('adminSnackbarUp', resp.data.response);
                });
            } else {
              this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
              this.$store.commit('unsetLoading');
              this.$emit('loginSuccess', '');
            }
          })
          .catch(err => {
            console.log(err, 'ERROR');
          });
      }
    });
  },
  methods: {
    loadOffers() {
      this.$emit('loadOffers');
    },
    openDialogFilters() {
      this.$emit('openDialogFilters');
    },
    sendEmail() {
      this.emailErrors = [];
      const endpoint = this.$store.state.endpoints.offers.admin.resendVerification;
      const payload = {
        email: this.email,
        url: this.$route.fullPath,
      };
      axios.post(endpoint, payload, this.jwtConfig)
        .then(() => {
          this.success_modal = true;
        })
        .catch(err => {
          this.emailErrors = err.response.data.response;
          console.log(err.response, 'ERROR RESEND');
        });
    },
  },
};
</script>

<style scoped>
  .rectangle-2 {
    height: 104px;
    width: auto;
    background-color: #FFFFFF;
  }
  .rectangle-copy-10 {
    height: 56px;
    width: 580px;
    border-radius: 47.5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.17);
    position: absolute;
    top: 0;
    margin-top: 25px;
    opacity: 0.9;
  }
  .puesto-empresa-o-pa-copy {
    margin-top: 17px;
    margin-left: 7px;
    color: rgba(174,174,174,0.87);
    font-size: 13.2px;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
  .oval-copy {
    height: 44px;
    width: 44px;
    opacity: 0.23;
    border-radius: 50%;
    background-color: #30B0BF;
    position: absolute;
  }
  .buscar {
    height: 25.2px;
    width: 221.48px;
    margin-bottom: 15px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.2px;
    line-height: 25.2px;
  }
  .rectangle-copy-18 {
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 6px;
    height: 55px;
  }
  .rectangle {
    border-radius: 6px;
    background-color: #E4F5F7;
  }
  .buscar-trabajo {
    height: 24px;
    width: 99px;
    color: #30B0BF;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 24px;
    margin-top: 15px;
    text-transform: initial;
  }
  .expired-link {
  color: #222B45;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
    text-align: center;
    margin-top: 20px;
}
  .expired-text {
  color: #222B45;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
  .inputs {
  box-sizing: border-box;
  height: 55px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin: 0px;
}
  .button-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
  .modal-signup-complete {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.title-modal-signup {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px;
  margin: 0px;
}
.text-modal-signup-complete {
  height: 61px;
  width: 350px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 17px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}
</style>
